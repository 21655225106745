<template>
  <div class="editor-panel" :class="{ floating }">
    <hub-button
      v-if="!excludedFunctions.includes('bold')"
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('bold') }"
      @click="
        editor
          .chain()
          .focus()
          .toggleBold()
          .run()
      "
    >
      <hub-icon :size="size" name="format-bold" />
    </hub-button>
    <hub-button
      v-if="!excludedFunctions.includes('italic')"
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('italic') }"
      @click="
        editor
          .chain()
          .focus()
          .toggleItalic()
          .run()
      "
    >
      <hub-icon :size="size" name="format-italic" />
    </hub-button>
    <hub-button
      v-if="!excludedFunctions.includes('strike')"
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('strike') }"
      @click="
        editor
          .chain()
          .focus()
          .toggleStrike()
          .run()
      "
    >
      <hub-icon :size="size" name="format-strikethrough-variant" />
    </hub-button>
    <!-- <hub-button
      variant="icon"
      class="control-button"
      @click="
        editor
          .chain()
          .focus()
          .unsetAllMarks()
          .run()
      "
    >
      clear marks
    </hub-button> -->
    <!-- <hub-button
      variant="icon"
      class="control-button"
      @click="
        editor
          .chain()
          .focus()
          .clearNodes()
          .run()
      "
    >
      clear nodes
    </hub-button> -->

    <hub-button
      v-if="!excludedFunctions.includes('heading')"
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
      @click="
        editor
          .chain()
          .focus()
          .toggleHeading({ level: 2 })
          .run()
      "
    >
      <hub-icon :size="size" name="format-header-1" />
    </hub-button>

    <hub-button
      v-if="!excludedFunctions.includes('bulletList')"
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('bulletList') }"
      @click="
        editor
          .chain()
          .focus()
          .toggleBulletList()
          .run()
      "
    >
      <hub-icon :size="size" name="format-list-bulleted" />
    </hub-button>
    <hub-button
      v-if="!excludedFunctions.includes('orderedList')"
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('orderedList') }"
      @click="
        editor
          .chain()
          .focus()
          .toggleOrderedList()
          .run()
      "
    >
      <hub-icon :size="size" name="format-list-numbered" />
    </hub-button>
    <!-- <hub-button
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('codeBlock') }"
      @click="
        editor
          .chain()
          .focus()
          .toggleCodeBlock()
          .run()
      "
    >
      <hub-icon :size="size" name="xml" />
    </hub-button> -->
    <hub-button
      v-if="!excludedFunctions.includes('blockquote')"
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('blockquote') }"
      @click="
        editor
          .chain()
          .focus()
          .toggleBlockquote()
          .run()
      "
    >
      <hub-icon :size="size" name="format-quote-close" />
    </hub-button>
    <!--  <hub-button variant="icon"
      class="control-button"
      
      @click="
        editor
          .chain()
          .focus()
          .setHorizontalRule()
          .run()
      "
    >
      horizontal rule
    </hub-button> -->
    <!-- <hub-button
      variant="icon"
      class="control-button"
      @click="
        editor
          .chain()
          .focus()
          .setHardBreak()
          .run()
      "
    >
      hard break
    </hub-button> -->
    <hub-button
      v-if="!excludedFunctions.includes('link')"
      variant="icon"
      :class="{ 'is-active': editor.isActive('link') }"
      class="control-button"
      @click="onAddLink('link')"
    >
      <hub-icon :size="size" name="link-variant" />
    </hub-button>
    <hub-button
      v-if="editor.isActive('link') && !excludedFunctions.includes('link')"
      variant="icon"
      class="control-button"
      @click="editor.commands.unsetLink()"
    >
      <hub-icon :size="size" name="link-variant-remove" />
    </hub-button>
    <hub-button
      v-if="enabledOptionalPlugins.includes('video')"
      variant="text"
      class="control-button"
      :class="{ 'is-active': editor.isActive('iframe') }"
      @click="onAddLink('video')"
    >
      <hub-icon :size="size" name="video-plus" />
    </hub-button>

    <hub-button
      variant="icon"
      class="control-button"
      @click="
        editor
          .chain()
          .focus()
          .undo()
          .run()
      "
    >
      <hub-icon :size="size" name="undo" />
    </hub-button>

    <hub-button
      variant="icon"
      class="control-button"
      @click="
        editor
          .chain()
          .focus()
          .redo()
          .run()
      "
    >
      <hub-icon :size="size" name="redo" />
    </hub-button>
    <div v-if="addingLink" class="link-holder">
      <div>
        <hub-text-field v-model="link" placeholder="Type or insert link" />
      </div>
      <div class="icons">
        <hub-button v-if="link" variant="icon" @click="addLink">
          <hub-icon name="check" />
        </hub-button>
        <hub-button variant="icon" @click="addingLink = null">
          <hub-icon name="close" />
        </hub-button>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import TextField from '@/components/common/TextField';

export default {
  components: {
    'hub-button': Button,
    'hub-icon': Icon,
    'hub-text-field': TextField
  },
  props: {
    editor: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      required: true
    },
    floating: {
      type: Boolean
    },
    excludedFunctions: {
      type: Array,
      default: () => ['']
    },
    enabledOptionalPlugins: {
      type: Array,
      default: () => ['']
    }
  },
  data() {
    return {
      link: '',
      addingLink: null,
      protocol: 'https'
    };
  },
  methods: {
    onAddLink(link) {
      if (this.editor.isActive('link')) {
        this.link = this.editor.getAttributes('link').href;
      } else if (this.editor.isActive('iframe')) {
        this.link = this.editor.getAttributes('iframe').src;
      } else {
        this.link = '';
      }
      this.addingLink = link;
    },
    addLink() {
      if (this.link) {
        if (this.addingLink === 'link') {
          const href = this.link.startsWith('http') ? this.link : `https://${this.link}`;
          this.editor.commands.setLink({ href });

          this.link = '';
          this.addingLink = '';
        }

        if (this.addingLink === 'video') {
          const srcMatch = new RegExp(/<iframe.+?src=\"(.+?)\".*?<\/iframe>/gim).exec(this.link);
          const widthMatch = new RegExp(/<iframe.+?width=\"(\d+?)\".+?<\/iframe>/gim).exec(this.link);
          const heightMatch = new RegExp(/<iframe.+?height=\"(\d+?)\".+?<\/iframe>/gim).exec(this.link);

          if (srcMatch && srcMatch[1]) {
            const option = {
              src: srcMatch[1]
            };

            if (widthMatch && widthMatch[1]) {
              option.width = `${widthMatch[1]}px`;
            }

            if (heightMatch && heightMatch[1]) {
              option.height = `${heightMatch[1]}px`;
            }

            this.editor
              .chain()
              .focus()
              .setIframe(option)
              .run();

            this.link = '';
            this.addingLink = '';
          } else if (this.link.startsWith('http')) {
            this.editor
              .chain()
              .focus()
              .setIframe({ src: this.link })
              .run();

            this.link = '';
            this.addingLink = '';
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.editor {
  .editor-panel {
    border-bottom: 1px solid var(--theme-highlight);
    margin: 2px;
    position: relative;
    &.floating {
      position: absolute;
      left: 0;
      width: inherit;
      transform: translateY(-100%);
      padding: 5px;
      background-color: var(--theme-surface);
      border: 1px solid var(--theme-highlight);
      margin: 0;
    }
    .control-button {
      border: 2px solid transparent;
      &.is-active,
      &:hover {
        border-color: var(--theme-surface);
        background-color: var(--theme-primary);
      }
    }
    .link-holder {
      padding: 5px;
      display: grid;
      grid-template-columns: 1fr max-content;
    }
  }
}
</style>
